/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError } from '@apollo/client';
import { GraphQLErrorExtensions } from 'graphql/error/GraphQLError';

export const buildErrorMessage = (error?: ApolloError): string => {
  if (error == null) return '';

  const errorDetails = buildErrorDetails(error);

  return errorDetails.filter((e) => e).join(', ');
};

const buildErrorDetails = (error: ApolloError): Array<string | unknown> => {
  const errors = error?.graphQLErrors?.map((e) => {
    const body = (e?.extensions?.response as GraphQLErrorExtensions)?.body;

    if (body instanceof Array) {
      return body.map((bodyItem) => {
        return bodyItemErrorMessage(bodyItem);
      });
    }

    return bodyItemErrorMessage(body);
  });

  return errors || [];
};

const bodyItemErrorMessage = (bodyItem: unknown) => {
  return (
    taskAnalysisAlreadyHasOneTargetStrategy(bodyItem) ||
    objectiveDescriptionAlreadyTaken(bodyItem) ||
    objectiveStrategyNotFound(bodyItem) ||
    clinicianNotAuthorized(bodyItem) ||
    subdomainItemScoresNotFound(bodyItem) ||
    vinelandReportWrongFormat(bodyItem) ||
    defaultStrategies(bodyItem)
  );
};

const subdomainItemScoresNotFound = (bodyItem: any) => {
  if (bodyItem?.details?.[0]?.errors?.[0]?.message === 'Subdomain Item Scores not found') {
    return 'O relatório não contém os scores dos Itens da Vineland.';
  }
};

const vinelandReportWrongFormat = (bodyItem: any) => {
  if (bodyItem?.details?.[0]?.errors?.[0]?.message === 'Report must be in docx format') {
    return 'O relatório precisa estar no formato docx.';
  }
};

const clinicianNotAuthorized = (bodyItem: any) => {
  const expectedMessage = 'Session can not be completed (unauthorized)';
  const errorMessage = bodyItem?.message || bodyItem?.message?.description || '';

  if (typeof errorMessage == 'string' && errorMessage.includes(expectedMessage)) {
    return 'Usuário sem autorização para completar a sessão.';
  }
};

const taskAnalysisAlreadyHasOneTargetStrategy = (bodyItem: any) => {
  if (bodyItem?.message?.intervention_program_id?.[0]) {
    return 'Programas do tipo Análise de Tarefas devem possuir um único Alvo.';
  }
};

const objectiveStrategyNotFound = (bodyItem: any) => {
  const errorMessage = bodyItem?.message || bodyItem?.message?.description || '';
  const coreErrorMessageStartWith =
    'some selected strategies does not belongs to the library objective';

  if (typeof errorMessage == 'string' && errorMessage.startsWith(coreErrorMessageStartWith)) {
    return 'Algumas das estratégias selecionadas não pertencem a esse objetivo';
  }
};

const objectiveDescriptionAlreadyTaken = (bodyItem: any) => {
  const errorMessage = bodyItem?.message;
  const isDescriptionError =
    errorMessage === 'Description has already been taken' ||
    errorMessage?.description?.some((item: string) => item === 'has already been taken');
  if (isDescriptionError) {
    return 'Já existe um objetivo com essa descrição.';
  }
};

const defaultStrategies = (bodyItem: unknown) => {
  if (typeof bodyItem === 'string') {
    return bodyItem;
  }

  return JSON.stringify(bodyItem);
};

export const customTargetListNameAlreadyTaken = (apolloError?: ApolloError) => {
  if (!apolloError) return;
  {
    const isUnprocessableEntityError = !!apolloError.graphQLErrors.find(
      (e) => (e.extensions.response as GraphQLErrorExtensions).status === 422,
    );
    if (isUnprocessableEntityError) {
      return 'Já existe uma lista com esse nome, por favor, verifique suas listas, inclusive as arquivadas.';
    }
  }
};
