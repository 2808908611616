import { gql } from '@apollo/client';
import { TASK_FRAGMENT } from '../task/fragments/taskFragment';
import { CLINICAL_GUIDANCE_PLANNING_BY_TASK_FRAGMENT } from 'queries/clinicalGuidancePlanning/fragments/clinicalGuidancePlanningByTaskFields';

export const GET_USER_TASKS_WITH_CLINICAL_GUIDANCE_PLANNING = gql`
  ${TASK_FRAGMENT}
  ${CLINICAL_GUIDANCE_PLANNING_BY_TASK_FRAGMENT}

  query getUserTasksWithClinicalGuidancePlanning(
    $clinicalCaseId: ID
    $contextType: ContextType
    $status: TaskStatus
    $subjectTypes: [SubjectType]
    $limit: Int
    $offset: Int
  ) {
    user {
      id
      tasks(
        clinicalCaseId: $clinicalCaseId
        contextType: $contextType
        status: $status
        subjectTypes: $subjectTypes
        limit: $limit
        offset: $offset
      ) {
        ...TaskFields
        ...ClinicalGuidancePlanningByTaskFields
        __typename
      }
    }
  }
`;
