import { gql } from '@apollo/client';

export const GET_SESSIONS_WITHOUT_CLINICAL_GUIDANCE_REGISTRY = gql`
  query getSessionsWithoutClinicalGuidanceRegistry(
    $clinicalCaseId: ID!
    $statuses: [SessionStatuses]
    $startScheduledAt: String
    $endScheduledAt: String
    $sessionTypes: [SessionTypes]
    $orderBy: SessionOrderByInput
  ) {
    sessions(
      clinicalCaseId: $clinicalCaseId
      statuses: $statuses
      sessionTypes: $sessionTypes
      startScheduledAt: $startScheduledAt
      endScheduledAt: $endScheduledAt
      orderBy: $orderBy
    ) {
      id
      clinicalCaseId
      discipline
      startScheduledAt
      endScheduledAt
      finished
      finalStartedAt
      finalEndedAt
      status
      statusDescription
      sessionType
      sessionTypeDescription
      observation
      cancellationReason
      cancellation {
        reason
        reasonDescription
        comment
        requestedByRoleDescription
        inAdvance
      }
      sessionable {
        ... on ClinicalGuidanceSessionable {
          registry {
            id
            status
          }
        }
        ... on InterventionSessionable {
          clinicalGuidanceRegistry {
            id
            status
          }
        }
        ... on ParentalTrainingSessionable {
          noteId
        }
      }
      createdAt
      updatedAt
      location {
        label
        labelDescription
      }
      clinicians {
        id
        name
      }
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
    }
  }
`;
