import { gql } from '@apollo/client';

export const CLINICAL_GUIDANCE_PLANNING_BY_TASK_FRAGMENT = gql`
  fragment ClinicalGuidancePlanningByTaskFields on Task {
    subject {
      id
      type
      ... on ClinicalGuidancePlanningSubject {
        planning {
          id
          status
          plannedAt
          note
          createdAt
          updatedAt
          mentor {
            id
            firstName
            lastName
          }
          mentee {
            id
            firstName
            lastName
          }
          clinicalCase {
            id
            name
          }
        }
      }
    }
  }
`;
