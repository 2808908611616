export const EESEL_WIDGET = 'eesel_widget';
export const PARENTAL_TRAINING_NOTE = 'parental_training_note';
export const CLINICAL_GUIDANCE_SUMMARIES = 'clinical_guidance_summaries';
export const USER_SESSIONS_PAGE = 'user_sessions_page';
export const USER_TASKS_PAGE = 'user_tasks_page';
export const USER_PENDENCIES_PAGE = 'user_pendencies_page';
export const USER_CLINICAL_HOMEPAGE = 'user_clinical_homepage';
export const HOME_ACTIVITIES = 'home_activities';
export const SKILL_ACQUISITION_NOTE = 'skill_acquisition_note';
export const MARKETPLACE_PAGE = 'marketplace_page';
export const SKILL_ACQUISITION_NOTE_REQUIRED_FIELD = 'skill_acquisition_note_required_field';
export const NEW_PEI_OBJECTIVE_BUTTON = 'new_pei_objective_button';
export const PEI_OBJECTIVE_DISCIPLINE_FORM = 'pei_objective_discipline_form';
export const DISABLE_INTERVENTION_COMPLETE_SESSION_PARTICIPANTS =
  'disable_intervention_complete_session_participants';
export const PEI_OBJECTIVE_STRATEGY = 'pei_objective_strategy';
export const SHOW_SUGGESTED_CHILD_PROGRESSION_NOTE = 'show_suggested_child_progression_note';
export const AUDIO_RECORDER_IN_SESSION_DETAILS = 'audio_recorder_in_session_details';
export const PLANNING_BY_CLINICAL_CASE_PAGE = 'planning_by_clinical_case_page';
export const CLINICAL_CASE_DETAILS_LINK = 'clinical_case_details_link';
export const FAMILY_SUPPORT_CENTER_LINK = 'family_support_center_link';
export const VINELAND_PEI_REPORTS = 'vineland_pei_reports';
export const REMOVE_RD = 'remove_rd';
export const CLINICAL_GUIDANCE_REGISTRY_V2 = 'clinical_guidance_registry_v2';
export const CLINICAL_GUIDANCE_PLANNING = 'clinical_guidance_planning';
